import gsap from "gsap";
import "../styles/index.scss";
import barba from "@barba/core";

let events = [];
let stories = [];

async function fetchData() {
  try {
    const response = await fetch("get_data.php");
    const data = await response.json();
    events = data.events;
    stories = data.stories;
    initializePage();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

function initializePage() {
  generateStoriesItems();
  cardFlipInit();
  calendar(events);
}

function generateStoriesItems() {
  const storiesList = document.querySelector(".stories-list");

  if (storiesList) {
    stories.forEach((story) => {
      const storyItem = document.createElement("div");
      storyItem.className = "stories-list-item";
      storyItem.innerHTML = `
        <div class="card-face card-face-front">
          <div class="storie-img-container">
            <img class="storie-img" src="${story.imgSrc}" alt="${story.frontTitle}" />
          </div>
          <div class="front-description-title">${story.frontTitle}</div>
          <div class="front-description-text">Подробнее...</div>
        </div>
        <div class="card-face card-face-back">
          <div class="back-description-title">${story.frontTitle}</div>
          <div class="back-description-text">${story.backText}</div>
          <div class="back-players-count">${story.playersCount}</div>
        </div>
      `;

      storiesList.appendChild(storyItem);
    });
  }
}

function enterTransition() {
  return gsap.to(".transition", {
    delay: 0.3,
    duration: 0.8,
    height: 0,
    ease: "power4.inOut",
  });
}

function leftEnterTransition() {
  return gsap.to(".left-transition", {
    scaleX: 0,
    duration: 0.8,
    ease: "power4.inOut",
    transformOrigin: "right center",
  });
}

function leftLeaveTransition() {
  return gsap.fromTo(
    ".left-transition",
    {
      scaleX: 0,
    },
    {
      delay: 0.3,
      duration: 0.8,
      ease: "power4.inOut",
      scaleX: 1,
      transformOrigin: "left center",
    }
  );
}

function rightEnterTransition() {
  return gsap.to(".right-transition", {
    scaleX: 0,
    duration: 0.8,
    ease: "power4.inOut",
    transformOrigin: "left center",
  });
}

function rightLeaveTransition() {
  return gsap.fromTo(
    ".right-transition",
    {
      scaleX: 0,
    },
    {
      delay: 0.3,
      duration: 0.8,
      ease: "power4.inOut",
      scaleX: 1,
      transformOrigin: "right center",
    }
  );
}

barba.init({
  sync: true,
  transitions: [
    {
      name: "opacity-transition",
      async leave(data) {
        if (
          data.current.namespace === "stories" ||
          data.trigger.dataset.to === "calendar"
        ) {
          await leftLeaveTransition();
        } else if (
          data.current.namespace === "calendar" ||
          data.trigger.dataset.to === "stories"
        ) {
          await rightLeaveTransition();
        }
      },
      enter(data) {
        if (
          data.current.namespace === "stories" ||
          data.trigger.dataset.to === "calendar"
        ) {
          leftEnterTransition();
        } else if (
          data.current.namespace === "calendar" ||
          data.trigger.dataset.to === "stories"
        ) {
          rightEnterTransition();
        }
        initializePage();
      },
      once(data) {
        enterTransition();
        initializePage();
      },
    },
  ],
});

function cardFlipInit() {
  const cards = document.querySelectorAll(".stories-list-item");
  if (cards) {
    cards.forEach((card) => {
      card.addEventListener("click", function () {
        card.classList.toggle("is-flipped");
      });
    });
  }
}

function calendar(events) {
  const calendar = document.querySelector(".calendar");
  if (calendar && Array.isArray(events)) {
    let currentYear = new Date().getFullYear();
    let currentMonth = new Date().getMonth();
    const now = new Date();

    const monthNames = [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ];

    function updateMonthYearDisplay() {
      const currentMonthYearDisplay =
        document.getElementById("currentMonthYear");
      currentMonthYearDisplay.textContent = `${monthNames[currentMonth]} ${currentYear}`;
    }

    function createCalendar(year, month, events) {
      calendar.innerHTML = "";

      const firstDayOfMonth = new Date(year, month, 1);
      const daysInMonth = new Date(year, month + 1, 0).getDate();

      const weekDays = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];

      let dayOfWeek = firstDayOfMonth.getDay();
      dayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;

      for (let i = 1; i < dayOfWeek; i++) {
        const emptyDayElement = document.createElement("li");
        emptyDayElement.className = "day empty";
        calendar.appendChild(emptyDayElement);
      }

      for (let i = 1; i <= daysInMonth; i++) {
        const date = new Date(year, month, i);
        const dateString = `${year}-${String(month + 1).padStart(
          2,
          "0"
        )}-${String(i).padStart(2, "0")}`;
        const event = events.find((event) => event.date === dateString);
        const weekDayIndex = (date.getDay() + 6) % 7;
        const weekDayName = weekDays[weekDayIndex];
        const dayElement = document.createElement("li");
        dayElement.className = "day";
        dayElement.innerHTML = `<time datetime="${year}-${
          month + 1
        }-${i}">${i} <span class="week-day">${weekDayName}</span></time><span class="day-time">день</span><span class="day-time">вечер</span>`;
        calendar.appendChild(dayElement);
        if (event) {
          if (event.isDayBusy === "1") {
            dayElement.querySelector(".day-time").classList.add("busy");
          }
          if (event.isEveningBusy === "1") {
            dayElement.querySelectorAll(".day-time")[1].classList.add("busy");
          }
        }
        calendar.appendChild(dayElement);
      }

      const today = new Date();
      if (year === today.getFullYear() && month === today.getMonth()) {
        const todayElement = calendar.querySelector(
          `time[datetime="${year}-${month + 1}-${today.getDate()}"]`
        ).parentNode;
        todayElement.classList.add("today");
      }
    }

    function updateCalendar() {
      createCalendar(currentYear, currentMonth, events);
      updateMonthYearDisplay();

      const prevMonthButton = document.getElementById("prevMonth");
      if (currentYear <= now.getFullYear() && currentMonth <= now.getMonth()) {
        prevMonthButton.disabled = true;
      } else {
        prevMonthButton.disabled = false;
      }
    }

    document.getElementById("prevMonth").addEventListener("click", function () {
      if (currentMonth === 0) {
        currentMonth = 11;
        currentYear--;
      } else {
        currentMonth--;
      }
      updateCalendar();
    });

    document.getElementById("nextMonth").addEventListener("click", function () {
      if (currentMonth === 11) {
        currentMonth = 0;
        currentYear++;
      } else {
        currentMonth++;
      }
      updateCalendar();
    });

    updateCalendar();
  }
}

fetchData();
